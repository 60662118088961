import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  Injectable,
  importProvidersFrom,
  mergeApplicationConfig,
} from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  browserPopupRedirectResolver,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import {
  Router,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import {
  BrowserTracing,
  TraceService,
  createErrorHandler,
  init as initSentryAngular,
  routingInstrumentation,
} from '@sentry/angular-ivy';
import {
  EnvironmentService,
  environment,
} from '@lancelot-frontend/environment';
import { CobrowseIO } from '@lancelot-frontend/plugins';
import { appClientConfig, sentryConfig } from './app.config.client';
import { appWebInitializerFactory } from './app-initializer.web';
import { AppPreloadingStrategy } from './app-preloading-strategy';
import { routes } from './app-routes.web';

if (environment.envName !== 'development') {
  initSentryAngular({
    ...sentryConfig,
    environment: environment.envName,
    dsn: environment.sentry.webAppDSN,
    release: environment.commitSha && `web-app@${environment.commitSha}`,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          environment.baseUrl,
          environment.cmsBaseUrl,
          environment.apiBaseUrl,
        ],
        routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.2,
  });
}

if (environment.envName !== 'development' && environment.envName !== 'robot') {
  CobrowseIO.setLicense('1K1J5UV2zGJjwg');
  CobrowseIO.start();
}

@Injectable()
export class WebErrorHandler implements ErrorHandler {
  sentryErrorHandler: ErrorHandler;

  constructor() {
    this.sentryErrorHandler = createErrorHandler();
  }

  handleError(error: unknown): void {
    if (
      error instanceof Error &&
      [
        /Loading chunk [\d]+ failed/,
        /dynamically imported module/,
        /Importing a module script failed/,
      ].some((regExp) => regExp.test(error.message))
    ) {
      window.location.reload();
    } else {
      this.sentryErrorHandler.handleError(error);
    }
  }
}

const webConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withPreloading(AppPreloadingStrategy),
      withInMemoryScrolling({ scrollPositionRestoration: 'disabled' }),
    ),
    importProvidersFrom(
      provideMessaging(() => getMessaging()),
      provideAuth(() =>
        initializeAuth(getApp(), {
          persistence: [indexedDBLocalPersistence, browserSessionPersistence],
          popupRedirectResolver: browserPopupRedirectResolver,
        }),
      ),
    ),
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useClass: WebErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        //NOOP
      },
      deps: [TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appWebInitializerFactory,
      deps: [EnvironmentService],
      multi: true,
    },
  ],
};

export const appWebConfig = mergeApplicationConfig(appClientConfig, webConfig);
