import {
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject, makeStateKey } from '@angular/core';

import {
  catchError,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { AppService } from '../app/app.service';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  private environmentService = inject(EnvironmentService);
  private app = inject(AppService);

  private cache: Map<string, boolean> = new Map();

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    // If we are requesting the api or the cms,
    // redo the request each time the refresh timestamp is updated
    if (
      request.method === 'GET' &&
      (request.url.startsWith(
        this.environmentService.get('apiBaseUrl', '/api'),
      ) ||
        request.url.startsWith(
          this.environmentService.get('cmsBaseUrl', '/cms'),
        ))
    ) {
      return this.app.refreshingTimestamp$.pipe(
        distinctUntilChanged(),
        switchMap((timestamp) => {
          if (!timestamp) {
            return next.handle(request);
          }

          const key = makeStateKey<string>(request.urlWithParams);
          const alreadyRefreshing = this.cache.get(key);

          if (!alreadyRefreshing) {
            this.cache.set(key, true);
            this.app.refreshingContent = true;
          }

          return next
            .handle(request.clone({ setParams: { r: timestamp.toString() } }))
            .pipe(
              tap((event) => {
                if (event.type === HttpEventType.Response) {
                  this.cache.delete(key);
                  this.app.refreshingContent = false;
                }
              }),
              catchError((error) => {
                this.cache.delete(key);
                this.app.refreshingContent = false;
                throw error;
              }),
            );
        }),
      );
    }

    return next.handle(request);
  }
}
