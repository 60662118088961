import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject, makeStateKey } from '@angular/core';

import { of } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { IS_CACHE_ENABLED } from '@lancelot-frontend/core';
import { AppService } from '../app/app.service';
import { AuthenticationService } from '../app/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class CacheInterceptor implements HttpInterceptor {
  private app = inject(AppService);
  private auth = inject(AuthenticationService);

  private cache: Map<string, HttpResponse<unknown>> = new Map();

  constructor() {
    // reset cache on sign-in/sign-out
    this.auth.firebaseUser$.pipe(skip(1)).subscribe(() => {
      this.cache.clear();
    });
    // reset cache on new refreshing timestamp
    this.app.refreshingTimestamp$.pipe(skip(1)).subscribe(() => {
      this.cache.clear();
    });
    // reset cache on login as
    this.app.loginAs$.pipe(skip(1)).subscribe(() => {
      this.cache.clear();
    });
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (req.context.get(IS_CACHE_ENABLED) === true) {
      const key = makeStateKey<string>(req.urlWithParams);
      const storedResponse = this.cache.get(key);
      if (storedResponse) {
        const response = new HttpResponse({
          body: storedResponse,
          status: 200,
        });
        return of(response);
      }

      return next.handle(req).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            const key = makeStateKey<string>(req.urlWithParams);
            this.cache.set(key, event.body);
          }
        }),
      );
    }

    return next.handle(req);
  }
}
